<template lang="pug">
.p-0
  .content-loader-center.m-0.h-100(v-if='isLoadingCompany || isCreatingCompany || isUpdatingCompany')
    .text-center.flex-center
      .loading-bg-inner 
        .loader
          .outer
          .middle
          .inner
      .mt-5
        br
        br
        br
        | {{ isLoadingCompany ? 'Chargement du formulaire d\'édition d\'une fiche société' : 'Enregistrement de la fiche société...' }}
  .h-100(v-else)
    b-row.m-0.justify-content-center
      b-col.content-header(cols='12' md="9")
        .d-flex.align-items-start.w-100.justify-content-between
          .d-flex
            h2.content-header-title.float-left.m-0.pr-1.mr-1(style="border-right: 1px solid #e2e2e2")
              | {{ ($route.meta.pageTitle[$route.meta.pageTitle.length-1] =='s' ? $route.meta.pageTitle.substring(0, $route.meta.pageTitle.length - 1) : $route.meta.pageTitle) }} 
            .content-header-search.mr-1
              h3.m-0(style="text-transform: capitalize;")
                | {{companyForm.isParticular ? civilityTranslate(companyForm.civility) + (companyForm.firstName ? companyForm.firstName : '') + ' ' + (companyForm.lastName ? companyForm.lastName : '') : companyForm.name }}
                span.ml-50(v-show="archive" class="text-danger") (archive)
          .content-header-actions.d-flex
            feather-icon.cursor(icon='XIcon' size='24' @click='cancel()')
    b-row.m-0.justify-content-center(style="height: calc(100% - 110px)")
      b-col.h-100(cols='12' md="9")
        form.h-100#formEditCompany(style="overflow-x: hidden;")
          .d-flex.pt-1.w-100.inputs-wrapper
            .d-flex.flex-column.mr-1
                b-form-group(label-for="companyIsParticular" label="Type de client")
                    b-form-checkbox.custom-control-primary.mt-50(id="companyIsParticular" v-model='companyForm.isParticular')
                        | Particulier
            .d-flex.flex-column.w-25.mr-1.w-100-mobile
                b-form-group(label='Code' label-for='companyCode')
                    ejs-textbox(id="companyCode" v-model='companyForm.code')
            .d-flex.flex-column.w-100.mr-1(v-if="!companyForm.isParticular && loaderIsParticular")
              b-form-group(label='Nom de la société' label-for='companyName')
                vue-typeahead-bootstrap#companyName(type='text' :maxMatches="100" :minMatchingChars="2" :showAllResults='true' name="companyName" v-model='companyForm.name' :data='autocompleteCompanyNameData' :serializer='item => item.nom_raison_sociale ? item.nom_raison_sociale : item.nom_complet' placeholder='Saisissez ou Rechercher une entreprise par nom ou par numéro de siret' @input='autocompleteCompanyNameSource()' @hit='autocompleteCompanyNameSelected' autocomplete='nope' aria-autocomplete='nope')
                  template(slot='suggestion' slot-scope='{ data, htmlText }')
                    .item
                      span {{ (data.nom_raison_sociale ? data.nom_raison_sociale : data.nom_complet) }}
                      small {{ (data.siege.numero_voie ? data.siege.numero_voie +' ' : '')+(data.siege.type_voie? data.siege.type_voie +' ' : '')+(data.siege.libelle_voie? data.siege.libelle_voie +' ' : '')+', '+data.siege.code_postal+ ' '+data.siege.libelle_commune}}
            .d-flex.flex-column.mr-1.w-25(v-if="companyForm.isParticular && loaderIsParticular")
              b-form-group(label-for="companyCivility"  label="Civilité *")
                ejs-dropdownlist(id='companyCivility' placeholder='Civilité' popupHeight='300px' sortOrder="Ascending" filterType='Contains' :allowFiltering='true' :dataSource='optionCivility' :fields="{ text: 'label', value: 'value' }" v-model='companyForm.civility')
            .d-flex.flex-column.mr-1.w-100(v-if="companyForm.isParticular && loaderIsParticular")
              b-form-group(label-for="companyFirstName"  label="Prénom")
                ejs-textbox(id="companyFirstName" placeholder="Prénom" v-model="companyForm.firstName" name="companyFirstName")
            .d-flex.flex-column.w-100.mr-1(v-if="companyForm.isParticular && loaderIsParticular")
              b-form-group(label-for="companyLastName"  label="Nom *")
                ejs-textbox(id="companyLastName" placeholder="Nom" v-model="companyForm.lastName" name="companyLastName" required="")
            .d-flex.flex-column.w-100-mobile
              b-form-group(label-for="companyColor" label="Couleur")
                  ejs-colorpicker(id="companyColor" v-model="companyForm.color" mode="Palette")
          b-row.my-0
            b-col(cols='12' style='padding-bottom:0px !important')
                b-form-group(label='Description' label-for='companyDescription')
                    ejs-textbox(id="companyDescription" :multiline="true" v-model='companyForm.description' placeholder="Description courte")
              
          vs-divider.mb-0.mt-2(color='#cccccc')
          div(v-for='(itemAddress, indexAddress) in companyForm.addresses' :key='indexAddress' style="display:contents")
            b-row.my-0
                b-col.pb-0.mb-0(cols='12')
                    b-form-group(:label-for="'labelAddress_' + indexAddress")
                        template(slot='label')
                            .d-flex.w-100.justify-content-between.align-items-center
                                label(:for="'labelAddress_' + indexAddress" :class="companyForm.addresses.length > 1 ? 'font-weight-bolder' : ''")
                                    h5 {{companyForm.addresses.length > 1 ? 'Adresse ' + (indexAddress + 1) : 'Adresse' }}
                                .d-flex.align-items-center(v-if='companyForm.addresses.length > 1')
                                  .d-flex.mr-1
                                    .d-flex.e-btn.e-primary.justify-space-center.align-items-center(v-if='itemAddress.isDefault' style="cursor: default!important")
                                      ejs-tooltip.chip(content="Cette adresse est l'adresse principale")
                                        span.link_icon.material-icons-outlined.m-0(style='font-size:16px') grade
                                    .d-flex.e-btn.e-outline.e-primary.justify-space-center.align-items-center.cursor-pointer(v-else-if="!itemAddress.isDefault" @click='addFavoriteAddress(indexAddress)')
                                      ejs-tooltip.chip(content="Définir comme adresse principale")
                                        span.link_icon.material-icons-outlined.m-0.cursor-pointer(style='font-size:16px') grade
                                  .d-flex.mr-1
                                    .d-flex.e-btn.e-primary.justify-space-center.align-items-center(v-if='itemAddress.isFacturable' @click='toggleFacturableAddress(indexAddress)')
                                      ejs-tooltip.chip(content="Cette adresse est l'adresse de facturation")
                                        span.link_icon.material-icons-outlined.m-0(style='font-size:16px') description
                                    .d-flex.e-btn.e-outline.e-primary.justify-space-center.align-items-center.cursor-pointer(v-else-if="!itemAddress.isFacturable" @click='toggleFacturableAddress(indexAddress)')
                                      ejs-tooltip.chip(content="Définir comme adresse de facturation")
                                        span.link_icon.material-icons-outlined.m-0.cursor-pointer(style='font-size:16px') description
                                  span.link_icon.material-icons-outlined.m-0.cursor-pointer(@click='deleteAddress(itemAddress, indexAddress)' style='font-size:18px') clear
                        b-form-input.mb-1(:id="'labelAddress_' + indexAddress" v-model='itemAddress.label' placeholder='Libellé (optionnel)')
                        vue-typeahead-bootstrap.not-autocomplete(type='text' :id="'address_' + indexAddress" v-model='itemAddress.address' :ieCloseFix='false' :data='autocompleteAddressData' :serializer='item => item.properties.label' :showAllResults='true' placeholder='Rechercher une adresse ou saisissez : Rue et n° de rue' @input='autocompleteAddressSource(indexAddress)' @hit='autocompleteAddressSelected' autocomplete='nope' aria-autocomplete='nope')
                            template(slot='suggestion' slot-scope='{ data, htmlText }')
                                .item
                                    small {{ data.properties.name }}
                                    span {{ data.properties.postcode+ ' ' + data.properties.city }}
                b-col.py-0.mb-1(cols='12')
                    b-form-input(:id="'addressComplement_' + indexAddress" v-model='itemAddress.addressComplement' placeholder='Bâtiment, Appartement..')
            b-row.my-0
                b-col.py-0.mb-1(cols='3')
                    b-form-input(:id="'zipcode_' + indexAddress" v-model='itemAddress.zipCode' placeholder='Code postal')
                b-col.py-0.mb-1(cols='9')
                    b-form-input(:id="'city_' + indexAddress" v-model='itemAddress.city' placeholder='Ville')
            b-row.my-0
                b-col.py-0.mb-1(cols='12')
                    b-form-input(:id="'country_' + indexAddress" v-model='itemAddress.country' placeholder='Pays')
            b-row.my-0
                b-col.py-0.mb-1(cols='12')
                    b-form-input(:id="'siret_' + indexAddress" v-model='itemAddress.siret' placeholder='Numéro de Siret')
            b-row.my-0
                b-col.py-0(cols='12')
                    label(for="address'") Coordonnées GPS
                b-col.py-0.mb-1(cols='3')
                    b-form-input(:id="'latitude_' + indexAddress" v-model='itemAddress.latitude' placeholder='Latitude')
                b-col.py-0.mb-1(cols='3')
                    b-form-input(:id="'longitude_' + indexAddress" v-model='itemAddress.longitude' placeholder='Longitude')
          b-row.my-0
            b-col.py-0(cols='12')
                small.cursor-pointer.text-primary(@click='addAddress') + Ajouter une adresse

          vs-divider.mb-0.mt-2(color='#cccccc')
          b-row.my-0
            b-col(md="12" lg="4" style='padding-bottom:0px !important')
                b-form-group(label-for="companyEmail"  label="Adresse email principale")
                    ejs-textbox(id="companyEmail" placeholder="Adresse email principale" v-model="companyForm.email" name="Email")
            b-col(md="12" lg="4"  style='padding-bottom:0px !important')
                b-form-group(label-for="companyPhoneNumber"  label="Téléphone principale")
                    ejs-textbox(id="companyPhoneNumber" placeholder="Téléphone principale" v-model="companyForm.phoneNumber" name="PhoneNumber")
            b-col(md="12" lg="4"  style='padding-bottom:0px !important')
                b-form-group(label-for="companySecondaryPhoneNumber"  label="Téléphone secondaire")
                    ejs-textbox(id="companySecondaryPhoneNumber" placeholder="Téléphone secondaire" v-model="companyForm.secondaryPhoneNumber" name="SecondaryPhoneNumber")
            b-col(md="12" lg="4"  style='padding-bottom:0px !important')
                b-form-group(label-for="website"  label="Site internet")
                    ejs-textbox(id='website' name="Site internet" placeholder='Ajouter votre site internet' v-model='companyForm.website')      
          vs-divider.mb-0.mt-2(color='#cccccc')

          b-row.my-0
            b-col(md="12" lg="6" xl="4" style='padding-bottom:0px !important')
                b-form-group(label-for="companySiren"  label="Siren")
                    ejs-textbox(id="companySiren" placeholder="Numéro de Siren" v-model="companyForm.siren" name="Siren")
            b-col(md="12" lg="6" xl="4" style='padding-bottom:0px !important')
                b-form-group(label-for="companyTva"  label="Numéro de TVA")
                    ejs-textbox(id="companyTva" placeholder="Numéro de TVA" v-model="companyForm.tvaNumber" name="Numéro de TVA")
            b-col(md="12" lg="6" xl="4" style='padding-bottom:0px !important')
                b-form-group(label-for="companyAccount"  label="Compte auxiliaire")
                    ejs-textbox(id="companyAccount" placeholder="Compte auxiliaire" v-model="companyForm.companyAccount" name="Compte auxiliaire")
          vs-divider.mb-0.mt-2(color='#cccccc')
          b-row.my-0
            b-col(cols='12')
                b-form-group(label-for="companyCollaboratorId"  label="Suivi par *")
                    ejs-dropdownlist(id='companyCollaboratorId' name="Collaborateur" placeholder='Selectionnez un collaborateur' :footerTemplate="'footerTemplate'" popupHeight='300px' sortOrder="Ascending" filterType='Contains' :allowFiltering='true' :dataSource="collaboratorsList.map(elem => { label = elem.firstName + ' ' + elem.lastName; return { ...elem, label: label }})" :fields="{ text: 'label', value: 'id' }" v-model='companyForm.collaboratorId' required)
                        template(v-slot:footerTemplate)
                            div.p-50
                                ejs-button(v-on:click.native="newCollaborator()" :content="'+ Nouveau collaborateur'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-open-link'" cssClass="w-100" iconPosition="Right")
        .flex.flex-column
          vs-divider.mb-50(color='#cccccc')
          b-row.my-0
              b-col.py-0(cols='12')
                  .d-flex.justify-content-between
                      div
                          ejs-button(v-on:click.native="cancel()" content="Annuler" :isPrimary="true" locale='fr' :cssClass="'e-outline mr-1'")
                          ejs-button(v-on:click.native="deleteCompanyLocal(companyForm.id, companyForm.label)" v-if="companyForm.id && !archive" content='' cssClass='e-outline e-danger m-0' iconCss='e-btn-sb-icons e-icon-archive'  :isPrimary="true" locale='fr')
                          ejs-button(v-on:click.native="archiveCompanyLocal(companyForm.id, companyForm.label)" v-if="companyForm.id && archive" content='' cssClass='e-outline e-danger m-0' iconCss='e-btn-sb-icons e-icon-trash'  :isPrimary="true" locale='fr')
                          ejs-button(v-on:click.native="restoreCompanyLocal(companyForm.id, companyForm.label)" v-if="companyForm.id && archive" content='' cssClass='e-outline e-warning m-0 ml-1' iconCss='e-btn-sb-icons e-icon-restore'  :isPrimary="true" locale='fr')
                      ejs-button(v-on:click.native="create" :disabled="(!companyForm.isParticular && !companyForm.name || (companyForm.name && companyForm.name.length<2)) || (companyForm.isParticular && !companyForm.lastName)" :content="companyForm.id ? 'Enregistrer' : 'Ajouter'" :isPrimary="true" locale='fr' :iconCss="'e-btn-sb-icons e-icons e-save'" iconPosition="Right")
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { debounce } from "lodash";
import { ECivility } from "@/types/api-orisis/enums/enums";

import { FormValidator } from "@syncfusion/ej2-vue-inputs";
import { MaskedTextBoxComponent } from "@syncfusion/ej2-vue-inputs";
import { civilityTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";

export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    "ejs-maskedtextbox": MaskedTextBoxComponent,
  },
  data() {
    return {
      loaderIsParticular: true,
      cssClass: "tool-tip-error",
      hoverDefaultAddressIndex: -1,
      hoverFacturableAddressIndex: -1,
      companyForm: {
        id: 0,
        addresses: [],
        contactIds: [],
        color: "#0c3571",
        code: null,
        email: null,
        phoneNumber: null,
        secondaryPhoneNumber: null,
        siret: null,
        website: null,
        tvaNumber: null,
        isParticular: false,
        name: null,
        civility: 0,
        lastName: null,
        firstName: null,
        notes: null,
        companyAccount: null,
        collaboratorId: null,
        companyTypeId: this.$route.params.companyTypeId,
        companyType: {
          name: this.$route.params.name,
          id: this.$route.params.companyTypeId,
        },
      },
      archive: false,
      autocompleteCompanyName: "",
      autocompleteCompanyNameData: [],
      autocompleteAddressData: [],
      autocompleteAddressIndex: 0,
      optionCivility: [
        {
          label: "M. et Mme",
          value: ECivility.MonsieurMadame,
        },
        {
          label: ECivility[ECivility.Monsieur],
          value: ECivility.Monsieur,
        },
        {
          label: ECivility[ECivility.Madame],
          value: ECivility.Madame,
        },

        {
          label: ECivility[ECivility.Autre],
          value: ECivility.Autre,
        },
      ],
      fieldsCategories: {
        dataSource: this.companyCategoriesTreeList,
        value: "id",
        text: "label",
        child: "children",
      },

      formObj: "",
      options: {
        rules: {
          Nom: {
            required: [true, "Indiquez le nom"],
          },
          Collaborateurs: {
            required: [true, "Sélectionnez au moins un collaborateur"],
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      "company",
      "isLoadingCompany",
      "companiesList",
      "isLoadingCompanyCategoriesTreeList",
      "companyCategoriesTreeList",
      "isLoadingUser",
      "usersList",
      "isLoadingCollaboratorsList",
      "isLoadingCompany",
      "collaboratorsList",
      "isCreatingCompany",
      "isUpdatingCompany",
    ]),
  },
  watch: {
    "companyForm.isParticular": function (newVal, oldVal) {
      this.loaderIsParticular = false;
      setTimeout(() => {
        this.loaderIsParticular = true;
      }, 50);
    },
    "companyForm.ownAddressCompany": function (newVal, oldVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.deteleAutocomplete();
        });
      }
    },
  },
  async created() {
    this.getCollaborators({});
    await this.popupCompanyEvent(this.id);
    this.deteleAutocomplete();
  },
  mounted() {
    this.formObj = new FormValidator("#formEditCompany", this.options);
  },
  methods: {
    ...mapActions([
      "createCompany",
      "getContacts",
      "getCollaborators",
      "getCompanyById",
      "updateCompany",
      "archiveCompanies",
      "deleteCompanies",
      "restoreCompanies",
    ]),
    civilityTranslate,
    async popupCompanyEvent(id) {
      this.getContacts({});
      if (id > 0) {
        await this.getCompanyById({ companyId: id }).then((res) => {
          this.companyForm = res;
          this.companyForm.companyTypeId = res.companyType.id;
          this.companyForm.contactIds = res.contacts
            ? res.contacts.map((elem) => {
                return elem.id;
              })
            : null;
          this.companyType = res.companyType;
          delete this.companyForm.companyType;
          this.dataOrigine = JSON.stringify(this.companyForm);
          // this.companyForm.jobId = res.job.id
          // this.companyForm.contractId = res.contract.id
        });
      } else {
        this.initializeForm();
      }
    },
    initializeForm() {
      this.companyForm = {
        id: 0,
        addresses: [],
        contactIds: [],
        color: "#0c3571",
        code: null,
        email: null,
        phoneNumber: null,
        secondaryPhoneNumber: null,
        siret: null,
        website: null,
        tvaNumber: null,
        isParticular: false,
        name: null,
        civility: 0,
        lastName: null,
        firstName: null,
        notes: null,
        companyAccount: null,
        collaboratorId: null,
        companyTypeId: this.$route.params.companyTypeId,
        companyType: {
          name: this.$route.params.name,
          id: this.$route.params.companyTypeId,
        },
        institutionId: this.institutionSelected.id,
      };
      this.companyType = {
        label: this.$route.params.name,
        id: this.$route.params.companyTypeId,
      };
      this.dataOrigine = JSON.stringify(this.companyForm);
    },
    create(e) {
      // let formStatus = this.formObj.validate();
      // if (formStatus) {
      const codeAlreadyExist = this.companyForm.code
        ? this.$store.getters["companiesList"].find(
            (company) =>
              company.code === this.companyForm.code &&
              company.id !== this.companyForm.id
          )
        : false;
      if (!codeAlreadyExist) {
        if (this.companyForm.isParticular == true) {
          let civility = this.optionCivility.find(
            (elem) => elem.value == this.companyForm.civility
          ).label;
          this.companyForm.name =
            (civility == "Autre" ? "" : civility + " ") +
            (this.companyForm.firstName ? this.companyForm.firstName : "") +
            " " +
            (this.companyForm.lastName ? this.companyForm.lastName : "");
        }
        if (this.companyForm.code?.length === 0) {
          this.companyForm.code = null;
        }
        if (this.companyForm.id) {
          this.updateCompany({
            company: this.companyForm,
          });
        } else {
          this.createCompany({ company: this.companyForm });
        }
        this.$nextTick(() => {
          if (this.$route.params.routeOrigine) {
            this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
          } else {
            this.$tabs.close({
              to:
                "/directory/companies/" +
                this.companyType.label.toLowerCase() +
                "/" +
                this.companyType.id,
            });
          }
        });
      } else {
        this.$bvModal.msgBoxConfirm("Veuillez mettre un autre code client.", {
          title: `Le code client ${this.companyForm.code} existe déjà.`,
          size: "sm",
          okTitle: "D'accord",
          okVariant: "primary",
          cancelTitle: "Fermer",
          cancelVariant: "outline-danger",
          hideHeaderClose: true,
          centered: true,
        });
      }

      // }
    },
    // Autocompte Company from data.gouv
    autocompleteCompanyNameSource: debounce(function () {
      let _this = this;
      fetch(
        `https://recherche-entreprises.api.gouv.fr/search?q=${this.companyForm.name}&page=1&per_page=25`
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.autocompleteCompanyNameData = data.results;
        });
    }, 500),
    autocompleteCompanyNameSelected(item) {
      this.companyForm.siret = item.siege.siret;
      this.companyForm.siren = item.siren;
      this.companyForm.addresses = [];
      this.companyForm.addresses.push({
        address:
          (item.siege.numero_voie ? item.siege.numero_voie + " " : "") +
          (item.siege.type_voie ? item.siege.type_voie + " " : "") +
          item.siege.libelle_voie,
        addressComplement: item.siege.complement_adresse,
        zipCode: item.siege.code_postal,
        city: item.siege.libelle_commune,
        country: "France",
        label: null,
        isDefault: true,
        latitude: item.siege.latitude,
        longitude: item.siege.longitude,
        id: 0,
      });
      this.companyForm.name = item.nom_raison_sociale
        ? item.nom_raison_sociale
        : item.nom_complet;
    },
    // Autocomplete Address from data.gouv
    autocompleteAddressSource: debounce(function (indexSource) {
      let _this = this;
      fetch(
        `https://api-adresse.data.gouv.fr/search/?q=${this.companyForm.addresses[indexSource].address}&autocomplete=0&limit=50`
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.autocompleteAddressData = data.features;
          this.autocompleteAddressIndex = indexSource;
        });
    }, 500),
    autocompleteAddressSelected(item) {
      this.companyForm.addresses[this.autocompleteAddressIndex].address =
        item.properties.name;
      this.companyForm.addresses[this.autocompleteAddressIndex].city =
        item.properties.city;
      this.companyForm.addresses[this.autocompleteAddressIndex].zipCode =
        item.properties.postcode;
      this.companyForm.addresses[this.autocompleteAddressIndex].latitude =
        item.geometry.coordinates[1];
      this.companyForm.addresses[this.autocompleteAddressIndex].longitude =
        item.geometry.coordinates[0];
      this.companyForm.addresses[this.autocompleteAddressIndex].country =
        "France";
    },
    deteleAutocomplete() {
      let elements = document.querySelectorAll(".not-autocomplete input");
      if (!elements) {
        return;
      }
      elements.forEach((element) => {
        element.setAttribute("autocomplete", "nope");
      });
      elements = document.querySelectorAll(".not-autocomplete");
      if (!elements) {
        return;
      }
      elements.forEach((element) => {
        element.setAttribute("autocomplete", "nope");
      });
    },
    addAddress() {
      this.companyForm.addresses.push({
        address: null,
        addressComplement: null,
        zipCode: null,
        city: null,
        country: null,
        label: null,
        isDefault: this.companyForm.addresses.length > 0 ? false : true,
        isFacturable: false,
        id: 0,
      });
      if (this.companyForm.addresses.length === 2) {
        this.addFavoriteAddress(0);
      }
      this.$nextTick(() => {
        this.deteleAutocomplete();
      });
    },
    addFavoriteAddress(index) {
      for (let i = 0; i < this.companyForm.addresses.length; i++) {
        const address = this.companyForm.addresses[i];
        if (i == index) {
          address.isDefault = true;
          if (address.isFacturable) {
            address.label = "Adresse de facturation - Adresse favorite";
          } else {
            address.label = "Adresse favorite";
          }
        } else {
          address.isDefault = false;
          if (address.isFacturable) {
            address.label = "Adresse de facturation";
          } else {
            address.label = "";
          }
        }
      }
    },
    toggleFacturableAddress(index) {
      if (!this.companyForm.addresses[index].isFacturable) {
        for (let i = 0; i < this.companyForm.addresses.length; i++) {
          const address = this.companyForm.addresses[i];
          if (i == index) {
            address.isFacturable = true;
            if (address.isDefault) {
              address.label = "Adresse de facturation - Adresse favorite";
            } else {
              address.label = "Adresse de facturation";
            }
          } else {
            address.isFacturable = false;
            if (address.isDefault) {
              address.label = "Adresse favorite";
            } else {
              address.label = "";
            }
          }
        }
      } else {
        this.companyForm.addresses[index].isFacturable = false;
        this.companyForm.addresses[index].label = "";
      }
    },
    deleteAddress(item, index) {
      this.companyForm.addresses.splice(index, 1);
    },
    archiveCompanyLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer cette fiche en consultant la liste de vos archives.",
          {
            title:
              "Êtes-vous sûr de vouloir archiver le " +
              this.companyType.label.toLowerCase() +
              ' "' +
              label +
              '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archiveCompanies({ companyIds: [id] });
            if (this.$route.params.routeOrigine) {
              this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
            } else {
              this.$tabs.close();
            }
          }
        });
    },
    restoreCompanyLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourez ré-utiliser cette fiche dans tous les modules de votre application.",
          {
            title:
              "Êtes-vous sûr de vouloir restaurer le " +
              this.companyType.label.toLowerCase() +
              ' "' +
              label +
              '" ?',
            size: "sm",
            okVariant: "warning",
            okTitle: "Restaurer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.restoreCompanies({ companyIds: [id] });
            if (this.$route.params.routeOrigine) {
              this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
            } else {
              this.$tabs.close();
            }
          }
        });
    },
    deleteCompanyLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Attention ! Vous ne pourrez plus récupérer cette fiche société.",
          {
            title:
              "Êtes-vous sûr de vouloir supprimer définitivement le " +
              this.companyType.label.toLowerCase() +
              ' "' +
              label +
              '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Supprimer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.deleteCompanies({ companyIds: [id] });
            if (this.$route.params.routeOrigine) {
              this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
            } else {
              this.$tabs.close();
            }
          }
        });
    },
    // Controls from
    cancel() {
      if (this.dataOrigine == JSON.stringify(this.companyForm)) {
        this.$tabs.close();
      } else {
        this.$bvModal
          .msgBoxConfirm("Cette action est définitive et irréversible.", {
            title:
              "Êtes-vous sûr de vouloir quitter l'édition sans enregistrer ?",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          })
          .then((value) => {
            if (value) {
              if (this.$route.params.routeOrigine) {
                this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
              } else {
                this.$tabs.close();
              }
            }
          });
      }
    },

    newContact(id) {
      if (this.companyForm.id) {
        this.$router.push({
          name: "new-contact",
          params: {
            routeOrigine: "directory/edit-company/" + this.companyForm.id,
          },
        });
      } else {
        this.$router.push({
          name: "new-contact",
          params: {
            routeOrigine:
              "directory/new-company/" +
              this.$route.params.name +
              "/" +
              this.$route.params.companyTypeId,
          },
        });
      }
    },
    newCollaborator() {
      if (this.affairForm.id) {
        this.$router.push({
          name: "new-collaborator",
          params: { routeOrigine: "edit-affair/" + this.affairForm.id },
        });
      } else {
        this.$router.push({
          name: "new-collaborator",
          params: { routeOrigine: "new-affair/" },
        });
      }
    },
  },
  computed: {
    ...mapGetters([
      "isLoadingCompany",
      "isLoadingContact",
      "isLoadingCollaboratorsList",
      "companiesTypesList",
      "contactsList",
      "collaboratorsList",
      "isCreatingCompany",
      "isUpdatingCompany",
      "institutionSelected",
      "workspaceSelected",
    ]),
  },
};
</script>
<style lang="scss">
.content-scrollable-sticky {
  // display: grid;
  // grid-template-columns: min-content 1fr;
  position: relative;
  .content-scrollable-sticky-nav {
    white-space: nowrap;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li a {
        display: block;
        padding: 1rem 0rem;
        color: white;
        text-decoration: none;
      }
      li a.current {
        background: black;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .inputs-wrapper {
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  .w-100-mobile {
    width: 100% !important;
  }
}

/* Only stick if you can fit */
@media (min-height: 300px) {
  .content-scrollable-sticky-nav ul {
    position: sticky;
    top: 0;
  }
}
</style>
